import React, {useContext, useEffect, useState} from 'react';
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/auth.context";
import {useRedirect} from "../hooks/redirect.hook";
import Popup from "../components/Popup";

const config = require('../config.json')

const AuthPage = () => {
    const [form, setForm] = useState({phone: "", checked: false})
    const [isFormValid, setIsFormValid] = useState(false)
    const [isNumberEntered, setIsNumberEntered] = useState(false)
    const [SMScode, setSMScode] = useState("")
    const [cardNum, setCardNum] = useState("")
    const [authError, setAuthError] = useState("")
    const [codeError, setCodeError] = useState("")
    const [isByCard, setIsByCard] = useState(true)
    const [isPopupVisible, setIsPopupVisible] = useState(false)

    const {request, error, clearError, status} = useHttp();
    const auth = useContext(AuthContext);
    const redirects = useRedirect();

    useEffect(() => {
        if (!error || error.length < 4 /*код ошибки*/) return

        setIsPopupVisible(true)
    }, [error])

    useEffect(() => {
        if (!status) return

        if (isNumberEntered && status === 404) {
            localStorage.setItem("_register", form.phone);
            redirects.register()
        }
    }, [status, isNumberEntered, redirects, form.phone])

    const closePopup = () => {
        setIsPopupVisible(false)
        clearError()
        setIsByCard(true)
        setIsNumberEntered(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!form.checked) return;

        if (form.phone.length === 11 && form.phone[0] === "7") {
            form.phone = form.phone.replace("7", "8");
        }

        request(
            "/auth/register",
            'POST',
            {username: form.phone},
            {Authorization: `Basic ${config.basicToken}`}
        ).then(() => {
            setIsNumberEntered(true)
        }).catch(e => {
            setAuthError("Ошибка отправки сообщения. Проверьте корректность номера или повторите попытку позже")
        })
    }

    const handleSubmitAuth = (e) => {
        e.preventDefault();
        let headers = {};
        headers['Authorization'] = `Basic ${config.basicToken}`;
        headers['Content-Type'] = 'application/x-www-form-urlencoded';

        request(
            "/auth/token",
            'POST',
            `username=${form.phone}&password=${SMScode}&grant_type=password`,
            headers
        ).then((data) => {
            auth.setTokens(data.access_token, data.refresh_token);
            if (cardNum) {
                clearError()
                request(
                    "/spump/owner/card",
                    'POST',
                    {CardID: `BEKAR${cardNum.padStart(10, "0")}`, Phone: form.phone},
                    {Authorization: `Bearer ${data.access_token}`}
                ).then((res) => {
                    if (res.ResultCode === -1) {
                        auth.setOwnerId(res.OwnerID)
                        localStorage.setItem("_register", form.phone);
                        redirects.register();
                    } else if (res.ResultCode === 0) {
                        auth.removeCardId()
                        auth.setOwnerId(res.OwnerID)

                        redirects.main();
                    }
                }).catch(e => {
                    console.log(error)
                })
                return
            }
            authOrRegister();
        }).catch(e => {
            setCodeError("Неверный код")
        })
    }

    const handleSubmitCard = (e) => {
        e.preventDefault();

        auth.setCardId(cardNum)
        setIsByCard(false)
    }

    const authOrRegister = () => {
        if (!localStorage.getItem("userData")) return;

        const token = JSON.parse(localStorage.getItem("userData")).accessToken;
        if (!token) return;

        request("/spump/owner", 'POST', {Phone: form.phone}, {Authorization: `Bearer ${token}`})
            .then(response => {
                const user = response[0];
                if (user) {
                    getCards(user.OWNER_ID)
                }
            })
            .catch(e => {
                if (status === 404) {
                    localStorage.setItem("_register", form.phone);
                    redirects.register()
                }
            })
    }

    const getCards = async (id) => {
        auth.setOwnerId(id);
        const token = JSON.parse(localStorage.getItem("userData")).accessToken;
        if (!token) return;

        try {
            const response = await request(`/spump/cards/${id}`, 'GET', null, {Authorization: `Bearer ${token}`});
            if (!response.length) {
                localStorage.setItem("_register", form.phone);
                redirects.initCard()
            } else if (response.length === 1) {
                redirects.main()
            } else {
                redirects.collapse()
            }
        } catch (e) {
            console.log("catch error")
        }
    }

    const handleCheckboxChange = async () => {
        await setForm({...form, checked: !form.checked});
    }

    const handleInputChange = (e) => {
        const val = e.target.value;
        if (isNaN(+val) || val.length > 11) {
            return
        }

        setForm({...form, phone: val});
    }

    const handleCodeChange = (e) => {
        if (e.target.value.length > 5 || isNaN(+e.target.value)) return;

        setSMScode(e.target.value)
    }

    const handleCardChange = (e) => {
        if (isNaN(+e.target.value)) return;

        setCardNum(e.target.value)
    }

    const authByPhone = () => {
        setCardNum("")
        auth.setCardId(null)
        setIsByCard(false)
    }

    useEffect(() => {
        const res = form.phone.trim() !== "" && form.checked;
        /*&&
            form.phone.trim().length === 11
        */
        setIsFormValid(res)
    }, [form]);

    useEffect(() => {
        if (!isNumberEntered) {
            return;
        }

        focusInput("SMS")
    }, [isNumberEntered]);

    useEffect(() => {
        focusInput("phoneNumber")
    }, []);

    const focusInput = (id) => {
        const input = document.getElementById(id);

        if (!input) {
            return;
        }

        input.focus()
    }

    if (isByCard) {
        return <div className={"auth wrapper"}>
            <div className={"logo-gray"}/>
            <form className={"form -auth"} onSubmit={handleSubmitCard}>
                <input placeholder={"Номер карты"} type={"text"} value={cardNum || ""}
                       onChange={handleCardChange}/>
                {/*<div className={"error"}>{codeError}</div>*/}
                <input type={"submit"} value={"Далее"} className={"button"}/>
            </form>
            <div className={"bonus-caption"}>
                У меня нет бонусной карты <span className={"bonus-caption__button"} onClick={authByPhone}>Создать виртуальную или войти по номеру телефона</span>
            </div>
        </div>
    }

    return (
        <div className={"auth wrapper"}>
            <div className={"logo-gray"}/>
            {
                !isNumberEntered
                    ? <form className={"form -auth"} onSubmit={handleSubmit}>
                        <div className={"backlink"} onClick={(() => setIsByCard(true))}>Вернуться к номеру карты</div>
                        <input id={"phoneNumber"} name={"phoneNumber"} placeholder={"Номер телефона"} type={"text"}
                               value={form.phone || ""}
                               onChange={handleInputChange}/>

                        <div className={"error"}>{authError}</div>

                        <input type={"submit"} value={"Получить код"} className={"button"}
                               disabled={!isFormValid}/>
                        <div className={"custom-checkbox"}>
                            <label className="container">
                                <input onChange={handleCheckboxChange} type="checkbox" checked={form.checked}/>
                                <span className="checkmark"/> Я соглашаюсь с правилами {" "}
                                <a rel="noopener noreferrer" target={"_blank"} href={"http://www.expert12.ru/dogovor.html"}>
                                    Пользовательского соглашения
                                </a>
                            </label>
                        </div>
                    </form>
                    : <form className={"form -auth"} onSubmit={handleSubmitAuth}>
                        <div className={"backlink"} onClick={() => setIsNumberEntered(false)}>Вернуться к номеру телефона
                        </div>
                        <input id={"SMS"} placeholder={"SMS-код"} type={"text"} value={SMScode || ""}
                               onChange={handleCodeChange}/>
                        <div className={"error"}>{codeError}</div>
                        <input type={"submit"} value={"Войти"} className={"button"}/>
                    </form>
            }
            {
                isPopupVisible ? <Popup hide={closePopup}>
                    <p className={"popup__text"}>
                        {error}<br/>
                        Проверьте правильность введенных данных
                    </p>
                    <div className={"popup__buttons"}>
                        <span className={"submit"} onClick={closePopup}>Ok</span>
                    </div>
                </Popup> : void 0
            }
        </div>
    )
}

export default AuthPage