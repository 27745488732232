import {createContext} from "react";

function noop() {
}

export const AuthContext = createContext({
    accessToken: null,
    refreshToken: null,
    setTokens: noop(),
    setOwnerId: noop(),
    setCardId: noop(),
    login: noop(),
    logout: noop(),
    userId: null,
    cardId: null,
    isAuthenticated: false,
    removeCardId: noop()
})